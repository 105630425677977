import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from '../router'
import http from "axios"
// import instance from "axios"
import '../api/mock.js'
import * as echarts from 'echarts'
//引入全局样式文件
import './assets/css/global.less'
//适配插件
import 'lib-flexible'
import AMap from 'vue-amap';
//自适应
import './utils/flexible.js'
Vue.use(AMap);

//树形结构图
import Vue2OrgTree from 'vue-tree-color'
Vue.use(Vue2OrgTree)


Vue.config.productionTip = false

Vue.use(ElementUI);
Vue.prototype.$echarts = echarts;
Vue.prototype.$http = http
// Vue.prototype.$http = instance

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

// 初始化vue-amap
AMap.initAMapApiLoader({
  // 高德key
  key: 'd3e0dcbb715f673423492f2c93d6c859',
  // 插件集合 （插件按需引入）
  // plugin: ['AMap.Geolocation']
  plugin: ['AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PlaceSearch', 'AMap.Geolocation', 'AMap.Geocoder'],
});