<template>
  <div id="app">
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <router-view></router-view>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    // HelloWorld
  },
  // mounted: function() {
  //   // 页面开始加载时修改font-size
  //   var html = document.getElementsByTagName("html")[0];
  //   var oWidth = document.body.clientWidth || document.documentElement.clientWidth;
  //   // 这里的750是指设计图的大小,自己根据实际情况改变
  //   html.style.fontSize = oWidth / 1130 * 100 + "px";
  //   console.log('rem:', html.style.fontSize);
  // }
}
</script>

<style>
::-webkit-scrollbar {
  /*隐藏滚轮*/
  display: none;
}

/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>
