import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path:'/',
        name:'screenPage',
        component:() => import('../views/screenPage')
        // component:() => import('../views/components/left3a')
        // component:() => import('../views/components/frame')
        // component:() => import('../views/components/middleBottom1')
        // component:Home
    },
    {
        path:'/user',
        name:'User',
        component:() => import('../views/User.vue')
    },
    {
        path:'/left1Page',
        name:'mainScreen',
        component:() => import('../views/left1Page')
        // component:Home
    },
]

const router = new VueRouter({
    mode:"history",
    routes
})

export default router